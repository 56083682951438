<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-row v-if="isBackBtn">
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1 mb-1"
            @click.prevent="goBack"
          >
            Back
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h2>Case {{ actionObj.CaseFileNumber }}</h2>
        </b-col>
        <b-col v-if="readOnly && !actionObj.IsLegalHold && userType !== 'Client'">
          <div class="float-right mr-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              :disabled="actionObj.ApprovedByManagerID != null || userType != 'User'"
              @click.prevent="editScreen()"
            >
              Edit
            </b-button>
          </div>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col>
          <h2>Action {{ actionObj.ActionFileNumber }}</h2>
        </b-col>
        <b-col v-if="actionObj.ActionTypeName">
          <div class="gray-rectangle">
            {{ actionObj.ActionTypeName }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div style="width: fit-content">
            <router-link
              :to="'/cases/' + CaseID + '/detail/ACTIONS'"
            >
              <h3 style="text-decoration: underline">
                Case {{ actionObj.CaseFileNumber }}
              </h3>
            </router-link>
          </div>
        </b-col>
        <b-col>
          <h3>Case State: {{ actionObj.CaseStateCode }}</h3>
        </b-col>
      </b-row>
      <b-row v-if="actionObj.IsLegalHold">
        <b-col>
          <h2><b
            class="text-nowrap"
            style="color:red"
          >
            Legal Hold
          </b></h2>
        </b-col>
      </b-row>

      <!-- Activities -->
      <div class="custom-p-2">
        <div class="accordion">
          <div
            class="accordion-title"
            @click="toggleAccordion('ACTIVITIES')"
          >
            <div
              class="collapse-arrow"
              :class="{ open: tab.ACTIVITIES }"
            />
            <h3>Activities</h3>
          </div>
          <b-row
            v-if="tab.ACTIVITIES && userType !== 'Client'"
            class="p-1"
          >
            <b-col>
              <Activities
                :action-i-d="ActionID"
                :activities="actionObj.Activities"
                :action-obj="actionObj"
                :legal-hold="actionObj.IsLegalHold"
                @deleteActivity="deleteActivity"
              />
            </b-col>
          </b-row>
        </div>
        <!-- Action Details -->
        <div class="accordion">
          <div
            class="accordion-title"
            @click="toggleAccordion('ACTION_DETAILS')"
          >
            <div
              class="collapse-arrow"
              :class="{ open: tab.ACTION_DETAILS }"
            />
            <h3>Action Details</h3>
          </div>
          <b-row v-if="tab.ACTION_DETAILS">
            <b-col class="p-1">
              <b-row>
                <b-col md="3">
                  <b-form-group
                    label="Action Status"
                    label-for="actionStatus"
                  >
                    <b-form-input
                      id="actionStatus"
                      v-model="ActionStatus"
                      :readonly="true"
                      placeholder=""
                      name="actionStatus"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group
                    label="Action File number"
                    label-for="actionFileNumber"
                  >
                    <b-form-input
                      id="actionFileNumber"
                      v-model="actionObj.ActionFileNumber"
                      :readonly="true"
                      placeholder=""
                      name="actionFileNumber"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group
                    label="Created By"
                    label-for="createdBy"
                  >
                    <b-form-input
                      id="createdBy"
                      v-model="CreatedBy"
                      :readonly="true"
                      placeholder=""
                      name="createdBy"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group
                    label="Date Action Created"
                    label-for="dateCreated"
                  >
                    <b-form-input
                      id="dateCreated"
                      :readonly="true"
                      :value="actionObj.Created | dateFormat"
                      placeholder=""
                      name="dateCreated"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="5">
                  <b-form-group
                    label="Action Type"
                    label-for="actionType"
                  >
                    <v-select
                      id="actionType"
                      v-model="ActionType"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="actionTypeOption"
                      placeholder=""
                      name="actionType"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    label="Description"
                    label-for="description"
                  >
                    <div
                      class="collapse-textarea"
                      :class="{expanded: !isExpandedDescription}"
                    >
                      <textarea
                        v-model="Description"
                        class="expandable-textarea"
                        :class="[isExpandedDescription ? 'not-expanded' : 'expanded']"
                        contentEditable
                        :readonly="readOnly"
                      />
                      <div @click="isExpandedDescription = !isExpandedDescription">
                        <span
                          class="expand-arrow mt-n1"
                          :class="[isExpandedDescription ? 'not-expanded' : 'expanded']"
                        >&#187;</span>
                      </div>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="2">
                  <b-form-group
                    label="Authorized Hours"
                    label-for="authorizedHours"
                  >
                    <b-form-input
                      id="authorizedHours"
                      v-model="AuthorizedHours"
                      type="number"
                      step="0.01"
                      min="0"
                      placeholder=""
                      name="authorizedHours"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <div class="d-flex">
                    <b-form-group
                      label="Time Window Start"
                      label-for="timeWindowStart"
                    >
                      <b-form-input
                        id="timeWindowStart"
                        v-model="TimeWindowStart"
                        type="time"
                        placeholder=""
                        name="timeWindowStart"
                        :readonly="readOnly"
                      />
                    </b-form-group>
                    <div style="width: 5rem;text-align: center;line-height: 5.5rem;font-size: 1.5rem;">
                      -
                    </div>
                    <b-form-group
                      label="Time Window End"
                      label-for="timeWindowEnd"
                    >
                      <b-form-input
                        id="timeWindowEnd"
                        v-model="TimeWindowEnd"
                        type="time"
                        placeholder=""
                        name="timeWindowEnd"
                        :readonly="readOnly"
                      />
                    </b-form-group>
                  </div>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    label="Scheduled Date"
                    label-for="scheduledDate"
                  >
                    <b-form-input
                      id="scheduledDate"
                      v-model="ScheduledDate"
                      type="date"
                      name="scheduledDate"
                      :readonly="true"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    label="Travel Required"
                    label-for="travelRequired"
                  >
                    <b-form-checkbox
                      id="travelRequired"
                      v-model="IsTravelRequired"
                      name="travelRequired"
                      :disabled="readOnly"
                    >
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group
                    label="Daily Instructions"
                    label-for="dailyInstructions"
                  >
                    <div
                      class="collapse-textarea"
                      :class="{expanded: !isExpandedDailyInstructions}"
                    >
                      <textarea
                        v-model="DailyInstructions"
                        class="expandable-textarea"
                        :class="[isExpandedDailyInstructions ? 'not-expanded' : 'expanded']"
                        contentEditable
                        :readonly="readOnly"
                      />
                      <div @click="isExpandedDailyInstructions = !isExpandedDailyInstructions">
                        <span
                          class="expand-arrow mt-n1"
                          :class="[isExpandedDailyInstructions ? 'not-expanded' : 'expanded']"
                        >&#187;</span>
                      </div>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="3">
                  <b-form-group
                    label="Invoice Number"
                    label-for="invoiceNumber"
                  >
                    <b-form-input
                      id="invoiceNumber"
                      v-model="actionObj.BillingInvoiceNumber"
                      :readonly="true"
                      placeholder=""
                      name="invoiceNumber"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group
                    label="Invoice Amount"
                    label-for="invoiceAmount"
                  >
                    <b-form-input
                      id="invoiceAmount"
                      :readonly="true"
                      :value="actionObj.BillingInvoiceAmount | amountFormat"
                      placeholder=""
                      name="invoiceAmount"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group
                    label="Action Billed on Date"
                    label-for="actionBilledOnDate"
                  >
                    <b-form-input
                      id="actionBilledOnDate"
                      :readonly="true"
                      :value="actionObj.BilledOnDate | dateFormat"
                      placeholder=""
                      name="actionBilledOnDate"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    label="Upsell"
                    label-for="upsell"
                  >
                    <b-form-checkbox
                      id="upsell"
                      v-model="Upsell"
                      name="upsell"
                      :disabled="readOnly"
                    >
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    label="Must Go"
                    label-for="mustGo"
                  >
                    <b-form-checkbox
                      id="mustGo"
                      v-model="MustGo"
                      name="mustGo"
                      :disabled="readOnly"
                    >
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    label="Hold"
                    label-for="hold"
                  >
                    <b-form-checkbox
                      id="hold"
                      v-model="Hold"
                      name="hold"
                      :disabled="readOnly"
                    >
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>

        <!-- Investigator Assignments -->
        <div
          v-if="getCurrentScopes.indexOf('st2.scheduleaction') !== -1"
          class="accordion"
        >
          <div
            class="accordion-title"
            @click="toggleAccordion('INVESTIGATOR_ASSIGNMENTS')"
          >
            <div
              class="collapse-arrow"
              :class="{ open: tab.INVESTIGATOR_ASSIGNMENTS }"
            />
            <h3>Assignments</h3>
          </div>
          <b-row
            v-if="tab.INVESTIGATOR_ASSIGNMENTS"
            class="pt-1 pb-1"
          >
            <b-col md="8" class="pt-1">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                :disabled="disabledAssignmentBtn()"
                @click.prevent="addInvestigatorAssignments()"
              >
                Add Investigator Assignment
              </b-button>
            </b-col>
            <b-col class="pt-1 pl-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="ml-3"
                :disabled="disabledAssignmentBtn()"
                @click.prevent="changeInvestigatorAssignments()"
              >
                Change Investigator Assignment
              </b-button>
            </b-col>
          </b-row>
          <b-row
            v-if="tab.INVESTIGATOR_ASSIGNMENTS"
            class="p-1"
          >

            <!-- Table -->
            <b-col class="border-top-dark min-height-10 rounded-sm">
              <b-row
                class="bg-light border-left-dark border-right-dark height-50 d-flex align-items-center"
                :class="{'border-bottom-dark': !rows.length}"
              >
                <b-col>Name</b-col>
              </b-row>
              <b-row
                v-for="(item, index) of rows"
                :key="index"
                class="border-left-dark border-right-dark d-flex align-items-center"
                :class="{'border-bottom-dark': index + 1 === rows.length}"
              >
                <b-col>
                  <b-row class="pt-1 border-top">
                    <b-col class="mb-1">
                      <router-link :to="'#'">
                        <span class="text-nowrap">{{ item.Fullname }}</span>
                      </router-link>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </div>
      <b-row
        v-if="!readOnly"
        class="pb-2 pt-2"
      >
        <b-col>
          <div class="d-flex justify-content-between">
            <div>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                :disabled="disabledSaveBtn"
                @click="resetForm()"
              >
                Cancel
              </b-button>
            </div>
            <div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                :disabled="disabledSaveBtn"
                @click.prevent="processForm()"
              >
                Save
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </validation-observer>
    <b-modal
      id="modal-delete-investigator"
      ok-only
      ok-variant="danger"
      ok-title="Delete investigator"
      modal-class="modal-danger"
      centered
      title="Confirm Delete investigator"
      @ok="confirmDeleteInvestigator()"
    >
      <div v-if="deleteAssignment !== null">
        Are you sure you want to delete Assignment Investigator {{ deleteAssignment.Fullname }} ?
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BModal,
  BFormCheckbox,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-good-table/dist/vue-good-table.css";
import { required } from "@validations";
import { mapGetters } from "vuex";
import APIService from "@core/utils/APIService";
import { ValidationObserver } from "vee-validate";

import Activities from "@/views/cases/components/Activities";
import vSelect from "vue-select";
const apiService = new APIService();

export default {
  components: {
    Activities,
    BRow,
    BCol,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BModal,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
  },
  filters: {
    dateFormat(val) {
      if (val) {
        const date = new Date(val);
        const options = {year: 'numeric', month: '2-digit', day: '2-digit'}
        if (date) return date.toLocaleDateString("en-US", options);
      }
    },
    amountFormat(val) {
      if (val) {
        return "$ " + val;
      }
    },
  },
  data() {
    return {
      disabledSaveBtn: false,
      isBackBtn: false,
      actionObj: {},
      CaseID: "",
      userType: "",
      ActionStatus: "",
      ActionFileNumber: "",
      CreatedBy: "",
      DateCreated: "",
      Description: "",
      IsTravelRequired: false,
      DailyInstructions: "",
      TimeWindowStart: "",
      TimeWindowEnd: "",
      ScheduledDate: "",
      ActionType: "",
      AuthorizedHours: "",
      Upsell: false,
      MustGo: false,
      Hold: false,
      rows: [],
      errors: [],
      actionTypeOption: [],
      required,

      tab: {
        ACTIVITIES: true,
        ACTION_DETAILS: true,
        INVESTIGATOR_ASSIGNMENTS: true,
      },
      readOnly: true,

      isExpandedDescription: true,
      isExpandedDailyInstructions: true,

      deleteAssignment: null,
    };
  },
  computed: {
    ...mapGetters({
      getCurrentScopes: "scopes/getCurrentScopes",
    }),
  },
  mounted() {
    this.userType = localStorage.getItem("userType");
  },
  async created() {
    try {
      this.ActionID = this.$route.params.actionID;
      this.isBackBtn = this.$route.meta.myCases;
      this.getActionTypes();
      if (this.ActionID) {
        this.loadAction();
      }
    } catch (err) {
      this.error = err;
    }
  },
  methods: {
    showToast(variant, position, timeout, data) {
      this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "InfoIcon",
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          }
      );
    },

    getActionTypes() {
      apiService
          .get("action/types")
          .then((response) => {
            this.actionTypeOption = response.data.map(item => {
              return {title: item.Name, value: item.Name, id: item.ActionTypeID}
            });
            this.actionTypeOption.sort((a,b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0))
          });
    },

    loadAction() {
      apiService
          .get("action/" + this.ActionID + "/detail")
          .then(res => {
            const options = { hour: '2-digit', minute: '2-digit', second: '2-digit' };
            const optionsDate = { day: '2-digit', month: '2-digit', year: 'numeric' };
            let TimeWindowStart = res.data.TimeWindowStart ? new Date(res.data.TimeWindowStart).toLocaleTimeString("en-US", options) : null;
            let TimeWindowEnd = res.data.TimeWindowEnd ? new Date(res.data.TimeWindowEnd).toLocaleTimeString("en-US", options) : null;
            this.TimeWindowStart = TimeWindowStart !== "Invalid Date" ? TimeWindowStart : res.data.TimeWindowStart;
            this.TimeWindowEnd = TimeWindowEnd !== "Invalid Date" ? TimeWindowEnd : res.data.TimeWindowEnd;
            this.CaseID = res.data.CaseID;
            this.ActionType = res.data.ActionTypeName;
            this.Upsell = res.data.Upsell;
            this.MustGo = res.data.MustGo;
            this.Hold = res.data.Hold;
            this.Description = res.data.Title;
            this.IsTravelRequired = res.data.IsTravelRequired;
            this.AuthorizedHours = res.data.AuthorizedHours;
            let SchedDate = res.data.ScheduledDate ? new Date(res.data.ScheduledDate).toLocaleDateString("en-US", optionsDate) : null;
            if (SchedDate && SchedDate !== "Invalid Date")
            {
              const [month, day, year] = SchedDate.split('/');
              this.ScheduledDate = year + '-' + month + '-' + day;
            }
            else  this.ScheduledDate = res.data.ScheduledDate;
            this.DailyInstructions = res.data.DailyInstructions;
            this.actionObj = res.data;
            this.ActionStatus = res.data.ApprovedByManagerID ? "Approved" : "Not Approved";
            this.rows = res.data.Assignments;
            const CreatedByUser = res.data.CreatedByUser;

            if (CreatedByUser) {
              apiService
                  .get("users/investigator,user")
                  .then(res => {
                    const user = res.data.Users.filter(i => i.UserAccountID === CreatedByUser)[0];
                    this.CreatedBy = user?.LastName + ", " + user?.FirstName;
                  })
            }
          })
    },

    goBack() {
      this.$router.go(-1)
    },

    toggleAccordion(tab) {
      this.tab[tab] = !this.tab[tab];
    },

    editScreen() {
      this.readOnly = false;
    },

    resetForm() {
      this.readOnly = true;
    },

    disabledAssignmentBtn() {
      return this.readOnly ? this.IsTravelRequired ? !(this.TimeWindowStart && this.TimeWindowEnd) : false : true;
    },

    addInvestigatorAssignments() {
      this.$router.push('/manager-scheduler/action/' + this.ActionID);
    },

    changeInvestigatorAssignments() {
      this.$router.push('/manager-scheduler/action-readonly/' + this.ActionID);
    },

    processForm() {
      const endpoint = "action/" + this.ActionID + "/edit";
      this.disabledSaveBtn = true;

      const postData = {
        "CaseID": this.CaseID,
        "ActionTypeID": this.ActionType.id,
        "Upsell": this.Upsell,
        "MustGo": this.MustGo,
        "Hold": this.Hold,
        "AuthorizedHours": this.AuthorizedHours,
        "TimeWindowStart": this.TimeWindowStart ? this.TimeWindowStart : null,
        "TimeWindowEnd": this.TimeWindowEnd ? this.TimeWindowEnd : null,
        "ScheduledDate": this.ScheduledDate === "" ? null : this.ScheduledDate,
        "Title": this.Description,
        "IsTravelRequired": this.IsTravelRequired,
        "DailyInstructions": this.DailyInstructions
      }
      apiService.post(endpoint, postData).then(res => {
        if (res) {
          this.showToast('success', 'top-center', 4000, 'Action updated');
          this.readOnly = true;
        }
        this.disabledSaveBtn = false;
      })
    },

    deleteAssignments(Assignment) {
      this.$bvModal.show("modal-delete-investigator");
      this.deleteAssignment = Assignment;
    },

    confirmDeleteInvestigator() {
      const endpoint = "action/unassign/investigator/" + this.deleteAssignment.AssignmentID
      apiService.delete(endpoint).then(res => {
        if (res) {
          this.showToast('success', 'top-center', 4000, 'Assignment delete!');
          this.deleteAssignment = null;
          this.loadAction();
        }
      })
    },

    deleteActivity() {
      this.loadAction();
    }
  },
};
</script>

<style>
hr {
  border: 1px solid #ebe9f1;
}
.accordion-title h3 {
  color: white;
  font-weight: bold;
  margin: 0.5rem;
}
.accordion-title {
  background: #476dae;
  display: flex;
  align-items: center;
}
.accordion {
  margin-top: 0.2rem;
}
.collapse-arrow {
  width: 1rem;
  height: 1rem;
  margin: 0 1rem;
  border: 3px solid white;
  border-top: none;
  border-left: none;
  transform: rotate(-135deg);
  transition-duration: 200ms;
}
.collapse-arrow.open {
  transform: rotate(45deg);
}
.text-underline {
  text-decoration: underline !important;
}

/* I believe p-2 is a bootstrap class. This fixes the spacing on the cases/add page. */
.custom-p-2 {
  margin: 1.5rem;
  margin-left: 0;
}
.height-input input {
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  width: 2.5rem;
}
</style>
